import 'normalize.css'
import 'styles/index.scss'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'

import Swiper, { Autoplay, EffectFade, Pagination } from 'swiper'

document.querySelectorAll('.image-slider').forEach((el) => {
  const slider = el.querySelector('.swiper')
  const numSlides = el.querySelectorAll('.swiper-slide').length

  if (numSlides > 1) {
    new Swiper(slider, {
      modules: [Autoplay, EffectFade, Pagination],
      autoplay: {
        delay: 3000,
      },
      effect: 'slide',
      fadeEffect: {
        crossFade: false,
      },
      loop: true,
      speed: 200,
      pagination: {
        el: el.querySelector('.slider-pagination'),
        clickable: true,
      },
    })
  }
})


